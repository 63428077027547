import GetUserEmptyLitterButton from 'components/helpers/postUserEmptyLitter';
import GetUserFeedButton from 'components/helpers/postUserFeed';
import GetUserPetButton from 'components/helpers/postUserPet';
import React, { useContext } from 'react';
import GetEnterInterest from 'components/helpers/useGetEnterInterst';
import GetUserPostDeposit from 'components/helpers/interest/postUserDeposit';
import GetUserPostCompound from 'components/helpers/interest/postUserCompound';
import { UserContext } from 'components/hooks/useUser';
import { useGetUserRewards } from 'components/helpers/interest/useGetUserRewards';
import { formatAmount } from '@multiversx/sdk-dapp/utils/operations';
import cacat_logo from 'img/cacat_logo.png';
import gas_logo from 'img/gas_logo.png';
import GetUserPostClaim from 'components/helpers/interest/postUserClaim';
import GetUserPostWithdraw from 'components/helpers/interest/postUserWithdraw';
import GetUserPostRobber from 'components/helpers/interest/postUserRobber';
import { useGetUserSavings } from 'components/helpers/interest/useGetUserSavings';
import GetUserPostVoteCacat from 'components/helpers/interest/postUserVoteCacat';
import LobbyMenu from 'components/LobbyMenu';

const MissionsPage = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('UserContextProvider');
  }
  const { user, setUser, refresh, setRefresh } = context;
  useGetUserSavings(user.address);
  useGetUserRewards(user.address);

  return (
    <div>
      <div className='grid-container'>
        <GetUserPostRobber />
        <GetUserPostVoteCacat />
      </div>{' '}
      <LobbyMenu back={'pet'} />
    </div>
  );
};

export default MissionsPage;
