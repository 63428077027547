import React, { useState } from 'react';
import { useContext } from 'react';
import { UserContext } from 'components/hooks/useUser';
import { Link } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import { c_actions } from 'config';

const AlertGasLow = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('UserContextProvider');
  }
  const { user, setUser, refresh, setRefresh } = context;

  const pet_upgrade_price = new BigNumber(
    new BigNumber(c_actions[1].upgrade.base_cost.toString()).plus(
      new BigNumber(c_actions[1].upgrade.lvl_cost.toString()).multipliedBy(
        user.pet_lvl.toString()
      )
    )
  );

  const feed_upgrade_price = new BigNumber(
    c_actions[3].upgrade.base_cost.toString() +
      c_actions[3].upgrade.lvl_cost.toString()
  ).multipliedBy(user.feed_lvl.toString());
  const play_upgrade_price = new BigNumber(
    c_actions[2].upgrade.base_cost.toString() +
      c_actions[2].upgrade.lvl_cost.toString()
  ).multipliedBy(user.play_lvl.toString());
  const litter_upgrade_price = new BigNumber(
    c_actions[0].upgrade.base_cost.toString() +
      c_actions[0].upgrade.lvl_cost.toString()
  ).multipliedBy(user.litter_lvl.toString());
  return (
    <>
      {user.cacat_amount.isGreaterThan(pet_upgrade_price) && (
        <div className='alert'>
          <div className='alert-text'>
            <Link
              to='/upgrade'
              className='inline'
              style={{ display: 'inline-block' }}
            >
              Upgrade your pet with cacat.
            </Link>
          </div>
        </div>
      )}

      {user.litter_percent >= 100
        ? BigInt(user.egld_amount) < BigInt(50 * 10 ** 13) && (
            <div className='alert'>
              <div className='alert-text'>
                Litter is full and gas is low.
                <br />
                Please{' '}
                <Link
                  to='/wallet'
                  className='inline'
                  style={{ display: 'inline-block' }}
                >
                  refill gas
                </Link>
                .
              </div>
            </div>
          )
        : user.energy < 50 &&
          user.litter_percent >= 100 && (
            <div className='alert'>
              <div className='alert-text'>
                Energy is low. Wait for it to refill or find {''}
                <Link
                  to='/missions'
                  className='inline'
                  style={{ display: 'inline-block' }}
                >
                  a mission
                </Link>{' '}
                to complete.
              </div>{' '}
              .
            </div>
          )}
    </>
  );
};

export default AlertGasLow;
