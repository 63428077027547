//DEVNET
export const defaultToken = 'CACAT-672714';
export const contractCacatActions =
  'erd1qqqqqqqqqqqqqpgqtvapzwc5lw223sjwe9y6ev97fc7nd2fkch9s7ksp20';
export const stakingContract =
  'erd1qqqqqqqqqqqqqpgql594j4q007n0srp6f5tgfyw3mdjc6cj5ch9sq662f3';
export const nativeAuth = true;
import { EnvironmentsEnum } from 'types';

interface NetworkType {
  id: 'devnet' | 'testnet' | 'mainnet';
  chainId: string;
  name: string;
  egldLabel: string;
  walletAddress: string;
  apiAddress: string;
  gatewayAddress: string;
  gatewayCached: string;
  explorerAddress: string;
  cacatApi: string;
}

export const network: NetworkType = {
  id: 'devnet',
  chainId: 'D',
  name: 'Devnet',
  egldLabel: 'xEGLD',
  walletAddress: 'https://devnet-wallet.multiversx.com/dapp/init',
  apiAddress: 'https://devnet-api.multiversx.com',
  gatewayAddress: 'https://devnet-gateway.multiversx.com',
  gatewayCached: 'https://devnet-gateway.multiversx.com',
  explorerAddress: 'https://devnet-explorer.multiversx.com',
  cacatApi: 'https://devnet-api.cacat.app'
};

export const apiTimeout = 6000;
export const transactionSize = 15;
export const TOOLS_API_URL = 'https://tools.multiversx.com';

/**
 * Calls to these domains will use `nativeAuth` Baerer token
 */
export const sampleAuthenticatedDomains = [TOOLS_API_URL];

export const minDust = '5000000000000000'; // 0.005 EGLD
export const decimals = 2;
export const denomination = 18;

export const sendFeedback = () => {
  if (navigator.vibrate) {
    navigator.vibrate(100);
  }
};

export const minGas = {
  claimGas: BigInt(50000000000000),
  pet: BigInt(90000000000000),
  litter: BigInt(100000000000000),
  petUpgrade: BigInt(200000000000000),
  litterUpgrade: BigInt(210000000000000),
  deposit: BigInt(220000000000000),
  compound: BigInt(140000000000000),
  robber: BigInt(360000000000000),
  vote_cacat: BigInt(5850000000000000)
};

export const shards = [
  'erd1qqqqqqqqqqqqqpgqzuv8fgn02d6dh6p2uzz3jlpvsdtj5hz99mzqmzdpt6',
  'erd1qqqqqqqqqqqqqpgqnc4ze3rhqtnse55874mrtaq2930hmxu4k86s6al3l6',
  'erd1qqqqqqqqqqqqqpgqxpfjz0jl3zr4tz6a3j26xp4a9ty2w22kn0pq2ulrxk'
];

export const c_actions = {
  0: {
    name: 'Litter',
    action: {
      energy_cost: 50,
      gas_cost: BigInt(500000000000000),
      base_cooldown: 0
    },
    upgrade: {
      energy_cost: 10,
      gas_cost: BigInt(210000000000000),
      base_cost: BigInt(0),
      lvl_cost: BigInt(30 * 10 ** 18),
      base_cooldown: 0
    },
    reward: {
      cost: 0,
      step: BigInt(30 * 10 ** 18),
      restep: BigInt(25 * 10 ** 18)
    }
  },
  1: {
    action_id: 1,
    name: 'Pet',
    action: {
      gas_cost: BigInt(0),
      energy_cost: 1,
      base_reward: BigInt(1 * 10 ** 18),

      base_cooldown: 0,
      clics: 5
    },
    upgrade: {
      gas_cost: BigInt(200000000000000),
      base_cost: BigInt(10 * 10 ** 18),
      lvl_cost: BigInt(3 * 10 ** 18),
      energy_cost: 10,
      base_cooldown: 0
    },
    reward: {
      clics: 10,
      cost: BigInt(10 * 10 ** 18),
      step: BigInt(3 * 10 ** 18),
      restep: BigInt(500)
    }
  },
  2: {
    action_id: 2,
    name: 'Play',
    action: {
      gas_cost: BigInt(0),
      energy_cost: 3,
      base_reward: BigInt(5 * 10 ** 18),
      base_cooldown: 600,
      clics: 30
    },
    upgrade: {
      gas_cost: BigInt(90000000000000),
      energy_cost: 10,
      base_cost: BigInt(50 * 10 ** 18),
      lvl_cost: BigInt(10 * 10 ** 18)
    },
    reward: {
      clics: 8,
      cost: BigInt(50 * 10 ** 18),
      step: BigInt(10 * 10 ** 18),
      restep: BigInt(250)
    }
  },
  3: {
    action_id: 3,
    name: 'Feed',
    action: {
      gas_cost: BigInt(0),
      energy_cost: 10,
      base_reward: BigInt(25 * 10 ** 18),
      base_cooldown: 14400
    },
    upgrade: {
      gas_cost: BigInt(90000000000000),
      energy_cost: 10,
      base_cost: BigInt(250 * 10 ** 18),
      lvl_cost: BigInt(25 * 10 ** 18)
    },
    reward: {
      clics: 5,
      cost: BigInt(250 * 10 ** 18),
      step: BigInt(25 * 10 ** 18),
      restep: BigInt(125)
    }
  },
  4: {
    action_id: 4,
    name: 'Upvote',
    action: {
      gas_cost: BigInt(0),
      energy_cost: 0,
      base_reward: BigInt(25 * 10 ** 18),
      base_cooldown: 14400
    },
    reward: {}
  },
  5: {
    action_id: 5,
    name: 'Robber',
    action: {
      gas_cost: BigInt(0),
      energy_cost: 30,
      base_reward: BigInt(25 * 10 ** 18),
      base_cooldown: 14400
    },
    reward: {}
  }
};

// Generate your own WalletConnect 2 ProjectId here: https://cloud.walletconnect.com/app
// export const walletConnectV2ProjectId = '47d072804da2e2809c16ea08eea608b9'; //flagged MS !?
export const walletConnectV2ProjectId = '9b1a9564f91cb659ffe21b73d5c4e2d8';

export const environment = EnvironmentsEnum.devnet;
