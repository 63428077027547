import React, { useState, useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import './TapGame.css';
import { formatAmount } from '@multiversx/sdk-dapp/utils/operations';
import cacat_logo from 'img/cacat_logo.png';
import gas_logo from 'img/gas_logo.png';
import energy_logo from 'img/energy_logo.png';
import { UserContext } from './hooks/useUser';
import { CountdownTimer } from './CountdownTimer';
import harvest_img from 'img/harvest.png';
import BigNumber from 'bignumber.js';

const TapGame = ({
  imageReady,
  imageLow,
  imageExec,
  gasCost,
  eneregyCost,
  userGas,
  userCacat,
  tokenCost,
  requiredClicks,
  onAction,
  disabled,
  matchingAction
}: any) => {
  const [clickCount, setClickCount] = useState(0);
  const [isExecuting, setIsExecuting] = useState(false);
  const intervalRef = useRef<any>(null);
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('App must be used within a UserContextProvider');
  }
  const { user, setUser, refresh, setRefresh } = context;

  // const ready_time: Date =
  //   matchingAction?.length > 0
  //     ? new Date(matchingAction[0]?.ready_at)
  //     : new Date(user.isoDate);

  // const timer = CountdownTimer(user.isoDate, ready_time);

  // const ready_time: Date =
  //   matchingAction?.length > 0
  //     ? new Date(matchingAction[0]?.ready_at)
  //     : new Date(user.isoDate);

  const timer = CountdownTimer(user.isoDate, matchingAction);

  const handleInteractionStart = (clic: number) => () => {
    if (isExecuting) return;
    if (gasCost > userGas) return;
    if (tokenCost > userCacat) return;
    if (disabled) return;
    setClickCount((prevCount) => {
      const newCount = prevCount + clic / 2;
      if (newCount >= requiredClicks) {
        executeAction();
      }
      return newCount;
    });
    // Vibration de 50 ms
    if (navigator.vibrate) {
      navigator.vibrate(50);
    }
  };

  const executeAction = () => {
    setIsExecuting(true);
    onAction();

    intervalRef.current = setTimeout(() => {
      setClickCount(0);
      setIsExecuting(false);
    }, 1000);
  };

  useEffect(() => {
    return () => {
      clearTimeout(intervalRef.current);
    };
  }, []);

  const progressPercentage = (clickCount / requiredClicks) * 100;
  return (
    <div className='tap-game' id='tap-game'>
      <div
        className={`tap-image ${isExecuting || refresh.txHash ? 'executing' : ''}`}
        onTouchStart={handleInteractionStart(1)}
        onMouseDown={handleInteractionStart(2)}
      >
        <img
          src={
            BigInt(gasCost) > BigInt(userGas)
              ? imageLow
              : new BigNumber(userCacat).isLessThan(tokenCost)
                ? imageLow
                : isExecuting
                  ? imageExec
                  : matchingAction?.length > 0 &&
                      matchingAction[0].type != 'direct' &&
                      timer <= 0
                    ? harvest_img
                    : imageReady
          }
          alt='Tap Game'
        />
      </div>
      <div className='progress-bar'>
        <div
          className='progress'
          style={{ width: `${progressPercentage}%` }}
        ></div>
      </div>
      <span className='text-with-icon'>
        {eneregyCost > BigInt(0) && (
          <>
            -
            {formatAmount({
              input: BigInt(eneregyCost).toString(),
              decimals: 0,
              digits: 0,
              showLastNonZeroDecimal: false,
              addCommas: true
            })}
            <img className='inline-incon' src={energy_logo} alt='gas-logo' />
          </>
        )}
        {gasCost > BigInt(0) && (
          <>
            -
            {formatAmount({
              input: (BigInt(gasCost) * BigInt(100000)).toString(),
              decimals: 18,
              digits: 2,
              showLastNonZeroDecimal: false,
              addCommas: true
            })}
            <img className='inline-incon' src={gas_logo} alt='gas-logo' />
          </>
        )}
        {tokenCost > BigInt(0) && (
          <>
            -
            {formatAmount({
              input: tokenCost?.toString(),
              decimals: 18,
              digits: 2,
              showLastNonZeroDecimal: false,
              addCommas: true
            })}
            <img className='inline-incon' src={cacat_logo} alt='cacat-logo' />
          </>
        )}
      </span>
      {refresh.txHash && <div className='overlay'>Executing...</div>}{' '}
      {disabled && <div className='overlayLow'>Disabled...</div>}{' '}
      {eneregyCost > user.energy && (
        <div className='overlayLow'>Low energy...</div>
      )}{' '}
      {timer > 0 && <div className='overlay'>{timer}</div>}
      {/* {matchingAction?.length > 0 ? <>READY</> : <>START</>} */}
    </div>
  );
};

const BigIntType = (props: any, propName: any, componentName: any) => {
  if (typeof props[propName] !== 'bigint') {
    return new Error(
      `Invalid prop \`${propName}\` supplied to` +
        ` \`${componentName}\`. Expected \`bigint\`.`
    );
  }
};
BigIntType.isRequired = (props: any, propName: any, componentName: any) => {
  if (props[propName] == null) {
    return new Error(
      `The prop \`${propName}\` is marked as required in` +
        ` \`${componentName}\`, but its value is \`${props[propName]}\`.`
    );
  }
  return BigIntType(props, propName, componentName);
};

TapGame.propTypes = {
  imageReady: PropTypes.string.isRequired,
  imageLow: PropTypes.string.isRequired,
  imageExec: PropTypes.string.isRequired,
  gasCost: BigIntType.isRequired,
  eneregyCost: BigIntType.isRequired,
  userGas: BigIntType.isRequired,
  userCacat: BigIntType.isRequired,
  tokenCost: BigIntType.isRequired,
  requiredClicks: PropTypes.number.isRequired,
  onAction: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  matchingAction: PropTypes.any
};
export default TapGame;
