import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { UserContext } from 'components/hooks/useUser';
import { network } from 'config';
import { pre } from 'telegraf/typings/format';

const GetRegisterButton = () => {
  const context = useContext(UserContext);
  const [submited, setSubmited] = useState(false);
  const [ref, setRef] = useState<any>(null);

  if (!context) {
    throw new Error('UserContextProvider');
  }
  const { user, setUser, refresh, setRefresh } = context;

  useEffect(() => {
    // console.log(window.location.hash);
    // console.log('webapp', window.Telegram.WebApp.initData);
    const urlParams = new URLSearchParams(window.Telegram.WebApp.initData);
    // console.log(urlParams);
    const refParam = urlParams.get('start_param');
    setRef(refParam);
  }, []);

  const postUserRegister = async () => {
    if (window.Telegram.WebApp.initData) {
      setSubmited(true);
      // alert("Verification registration ");
      const initData = window.Telegram.WebApp.initData;
      try {
        const response = await axios.post(network.cacatApi + '/user/register', {
          initData
        });

        const data = response.data;
        console.log(data);
        if (data.message) {
          alert(data.message);
        }
        if (data.success) {
          setUser((prevUser) => ({
            ...prevUser,
            check: true,
            id: data.user.id,
            address: data.user.address,
            shard: data.user.shard,
            gas: data.user.gas,
            energy: data.user.energy,
            litter: data.litter
          }));

          setRefresh((r) => ({
            ...r,
            refreshing: true,
            user_vm: true,
            txHash: data.txHash
          }));
        } else {
          alert('registration failed');
          await console.log(
            `User registration failed: ${JSON.stringify(data)}`
          );
        }
      } catch (error) {
        alert('Error during registration');
        await console.log(`Error during registration: ${error}`);
      }
    }
  };

  return (
    <>
      {ref ? (
        <p>Your referral code is: {ref}</p>
      ) : (
        <p>No referral code provided.</p>
      )}{' '}
      <button disabled={submited} onClick={postUserRegister}>
        Register
      </button>
    </>
  );
};

export default GetRegisterButton;
