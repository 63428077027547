import axios from 'axios';
import { useEffect, useContext, useState } from 'react';
import {
  Address,
  AddressValue,
  ContractFunction,
  ResultsParser
} from '@multiversx/sdk-core/out';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers';
import { c_actions, defaultToken, network } from 'config';
import { getScByShard } from 'components/helpers/smartContracts';
import { UserContext } from 'components/hooks/useUser';
import { calculateTotalReward } from './helpers/RewardCalcul';
import BigNumber from 'bignumber.js';

const resultsParser = new ResultsParser();

const useGetAccountInfo = (address: any, shard: any) => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('UserContextProvider');
  }
  const { user, setUser, refresh, setRefresh } = context;

  const fetchTxHash = async () => {
    try {
      const response = await axios.get(
        network.apiAddress + '/transactions/' + refresh.txHash
      );

      //if succes or fail tx ended
      if (response.data.status == 'success' || response.data.status == 'fail') {
        //ajouter 2 sec d'attente avant de set comme terminé
        setTimeout(() => {
          setRefresh((r) => ({
            ...r,
            txHash: '',
            refreshing: false
          }));
        }, 2000);
      }
    } catch (err: any) {
      console.error('Unable to call userWallet', err);
    }
  };

  //get EGLD balance
  const fetchAccountEgld = async () => {
    try {
      const response = await axios.get(
        network.apiAddress + '/accounts/' + user.address
      );
      const balance = response?.data?.balance;
      if (response?.data?.address) {
        setUser((prevUser) => ({
          ...prevUser,
          check: true,
          egld_amount: balance ? balance : BigInt(0)
        }));
        setRefresh((r) => ({
          ...r,
          user_egld: false,
          refreshing: false
        }));
      }
    } catch (err: any) {
      console.error('Unable to call userWallet', err);
    }
  };

  //Get cacat balance
  const fetchAccountCacact = async () => {
    setRefresh((r) => ({
      ...r,
      user_cacat: false,
      refreshing: false
    }));

    try {
      const balance = await axios.get(
        network.apiAddress +
          '/accounts/' +
          user.address +
          '/tokens/' +
          defaultToken
      );

      if (balance?.data?.balance) {
        setUser((prevUser) => ({
          ...prevUser,
          check: true,
          cacat_amount: new BigNumber(balance?.data?.balance)
        }));
      }

      // setRefresh((r) => ({
      //   ...r,
      //   user_cacat: false,
      //   refreshing: false
      // }));
    } catch (err: any) {
      //wallet with no cacat return a 404
      // https://devnet-api.multiversx.com/accounts/erd1s2tstpvulqzhppydk876ydf6zce8svfznpe460plqnj0je5qx83qew5k2l/tokens/CACAT-672714
      // {"statusCode":404,"message":"Token for given account not found"}

      console.error('Unable to call usercacat', err);
    }
  };

  const fetchAccountVM = async () => {
    try {
      const query = getScByShard(shard).createQuery({
        func: new ContractFunction('getUserInfo'),
        args: [new AddressValue(new Address(user.address))]
      });

      const proxy = new ProxyNetworkProvider(network.gatewayAddress);

      const queryResponse = await proxy.queryContract(query);

      const endpointDefinition = getScByShard(shard).getEndpoint('getUserInfo');
      const { firstValue: position } = resultsParser.parseQueryResponse(
        queryResponse,
        endpointDefinition
      );
      const tab = position?.valueOf();
      if (tab) {
        const current = calculateTotalReward(user.litter, tab.litter_lvl);
        const limit =
          BigInt(tab.litter_lvl) * BigInt(c_actions[0].reward.restep);
        const percent = (Number(current) / Number(limit)) * 100;
        setUser((prevUser) => ({
          ...prevUser,
          litter_amount: tab.litter_amount,
          litter_lvl: tab.litter_lvl,
          litter_limit: limit,
          pet_lvl: tab.pet_lvl,
          feed_lvl: tab.feed_lvl,
          play_lvl: tab.play_lvl,
          litter_calcul: current,
          litter_percent: percent
        }));
      } else {
        // setUser((prevUser) => ({
        //   ...prevUser,
        //   litter_amount: BigInt(0),
        //   litter_lvl: BigInt(0),
        //   pet_lvl: BigInt(0),
        //   feed_lvl: BigInt(0)
        // }));
      }
      setRefresh((r) => ({
        ...r,
        user_vm: false
      }));
    } catch (err) {
      console.error('Unable to call getUserInfo', err);
    }
  };

  useEffect(() => {
    if (
      (!refresh.user_egld && !refresh.user_cacat && !refresh.user_vm) ||
      !address ||
      shard === null
    ) {
      return;
    }

    let interval: any;
    if (refresh.user_egld || refresh.user_cacat || refresh.user_vm) {
      const executeFetch = async () => {
        if (refresh.txHash) {
          await fetchTxHash();
        } else {
          if (refresh.user_egld) {
            fetchAccountEgld();
          }
          if (refresh.user_cacat) {
            fetchAccountCacact();
          }
          if (refresh.user_vm) {
            fetchAccountVM();
          }
        }
      };

      // Exécution immédiate

      // Exécution toutes les 10 secondes
      if (refresh.refreshing) {
        // alert('call int');
        interval = setInterval(executeFetch, 4000);
      } else {
        // alert('call direct');
        executeFetch();
      }
    }

    return () => clearInterval(interval);
  }, [refresh, address]);
  return;
};

export default useGetAccountInfo;
