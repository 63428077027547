import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { network } from 'config';
import { calculateTotalReward } from 'components/helpers/RewardCalcul';
import BigNumber from 'bignumber.js';

interface User {
  check: boolean;
  id: number | null;
  address: string;
  shard: number | null;
  gas: number;
  energy: number;
  pet_lvl: bigint;
  feed_lvl: bigint;
  play_lvl: bigint;
  litter_lvl: bigint;
  litter_limit: bigint; //Capacité MAX de la littière pour l'utilisateur
  litter_amount: bigint; //Deprecié : montant cacat littière onchain
  litter_calcul: bigint; //calcul théorique offchain de la litter
  litter_percent: number; //pourcentage de remplissage
  egld_amount: bigint;
  cacat_amount: BigNumber;
  actions: [];
  isoDate: Date;
  litter: [];
  savings: BigNumber;
  rewards: BigNumber;
  bank_balance: BigNumber;
}

interface Refresh {
  user_vm: boolean;
  user_egld: boolean;
  user_cacat: boolean;
  user_verif: boolean;
  refreshing: boolean;
  txHash: string;
  savings: boolean;
  rewards: boolean;
}

interface UserContextType {
  user: User;
  setUser: React.Dispatch<React.SetStateAction<User>>;
  refresh: Refresh;
  setRefresh: React.Dispatch<React.SetStateAction<Refresh>>;
}

export const UserContext = createContext<UserContextType | undefined>(
  undefined
);

export const UserContextProvider: React.FC = ({ children }: any) => {
  const [refresh, setRefresh] = useState<Refresh>({
    user_vm: true,
    user_egld: true,
    user_cacat: true,
    user_verif: true,
    refreshing: false,
    txHash: '',
    savings: true,
    rewards: true
  });
  const currentDate = new Date();

  const [user, setUser] = useState<User>({
    check: false,
    id: null,
    address: '',
    shard: null,
    gas: 0,
    energy: 0,
    pet_lvl: BigInt(1),
    feed_lvl: BigInt(0),
    play_lvl: BigInt(0),
    litter_lvl: BigInt(0),
    litter_limit: BigInt(0),
    litter_amount: BigInt(0),
    litter_calcul: BigInt(0),
    litter_percent: 0,
    egld_amount: BigInt(0),
    cacat_amount: new BigNumber(0),
    actions: [],
    isoDate: currentDate,
    litter: [],
    savings: new BigNumber(0),
    rewards: new BigNumber(0),
    bank_balance: new BigNumber(0)
  });

  useEffect(() => {
    const verifyUser = async () => {
      if (window.Telegram.WebApp.initData) {
        const initData = window.Telegram.WebApp.initData;
        try {
          // console.log(JSON.stringify(initData));

          const response = await axios.post(`${network.cacatApi}/user/verify`, {
            initData
          });
          const data = response.data;
          if (data.success) {
            setUser((prevUser) => ({
              ...prevUser,
              check: true,
              id: data?.user?.id ? data?.user?.id : null,
              address: data?.user?.address ? data?.user?.address : '',
              shard: data?.user?.shard !== null ? data?.user?.shard : null,
              gas: data?.user?.gas ? data?.user?.gas : 0,
              energy: data?.user?.energy ? data?.user?.energy : 0,
              actions: data?.actions,
              isoDate: data?.currentTime
                ? new Date(data?.currentTime)
                : currentDate,
              litter: data?.litter
            }));
          }
        } catch (error) {
          console.error('Error during verification:', error);
        }
      }
    };
    verifyUser();

    const interval = setInterval(verifyUser, 60000); // Par exemple, toutes les 60 secondes

    // Nettoyer l'intervalle lorsqu'il n'est plus nécessaire
    return () => clearInterval(interval);
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, refresh, setRefresh }}>
      {children}
    </UserContext.Provider>
  );
};
