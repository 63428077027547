import React, { useContext } from 'react';
import axios from 'axios';
import { UserContext } from 'components/hooks/useUser';
import { formatAmount } from '@multiversx/sdk-dapp/utils/operations';
import { c_actions, minGas, network } from 'config';
import litter1 from 'img/litter1.png';
import litter2 from 'img/litter2.png';
import litter3 from 'img/litter3.png';
import cacat_logo from 'img/cacat_logo.png';
import gas_logo from 'img/gas_logo.png';
import TapGame from 'components/TapGame';
import { calculateTotalReward } from './RewardCalcul';

const GetUserEmptyLitterDbButton = () => {
  const action_id = 0;
  const action_type = 'action';
  const currentDate = new Date();
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('UserContextProvider');
  }
  const { user, setUser, refresh, setRefresh } = context;

  const postUserEmptyLitter = async () => {
    if (window.Telegram.WebApp.initData) {
      const initData = window.Telegram.WebApp.initData;
      try {
        const response = await axios.post(
          network.cacatApi + '/user/emptylitteroff',
          {
            initData
          }
        );

        const data = response.data;
        if (data.message) {
          alert(data.message);
        }
        if (Array.isArray(data?.actions)) {
          setUser((prevUser) => ({
            ...prevUser,
            litter: []
          }));
        }
        if (data.success) {
          const current = calculateTotalReward(user.litter, user.litter_lvl);
          const percent = (Number(current) / Number(user.litter_limit)) * 100;

          setUser((prevUser) => ({
            ...prevUser,
            litter: data.litter ? data.litter : user.litter,
            energy:
              data.user.energy !== undefined && data.user.energy !== null
                ? data.user.energy
                : user.energy,
            litter_calcul: current,
            litter_percent: percent
          }));
          setRefresh((r) => ({
            ...r,
            user_vm: true,
            user_egld: true,
            user_cacat: true,
            refreshing: true,
            txHash: data.txHash
          }));
        } else {
          alert('EmptyLitter failed');
          await console.log(`EmptyLitter failed: ${JSON.stringify(data)}`);
        }
      } catch (error) {
        alert('EmptyLitter failed');
        await console.log(`Error during EmptyLitter: ${error}`);
      }
    }
  };
  const matchingAction: any = user?.actions?.filter(
    (action: any) => action.action_id === action_id
  );

  // const litter_calcul = calculateTotalReward(user.litter, user.litter_lvl);

  return (
    <div className='item'>
      {c_actions[action_id].name}{' '}
      <span className='text-with-icon'>
        {user.litter_calcul > BigInt(0) ? (
          <>
            +
            {formatAmount({
              input:
                BigInt(user.litter_calcul) > BigInt(user.litter_limit)
                  ? user.litter_limit.toString()
                  : user.litter_calcul.toString(),
              decimals: 18,
              digits: 2,
              showLastNonZeroDecimal: false
            })}
          </>
        ) : (
          '0'
        )}{' '}
        <img className='inline-incon' src={cacat_logo} alt='cacat-logo' />
        {user.litter_limit > 0 &&
          BigInt(
            (user.litter_calcul * BigInt(100)) / user.litter_limit
          ).toString()}{' '}
        %
      </span>
      <TapGame
        imageReady={
          BigInt(user.litter_calcul) >= BigInt(user.litter_limit)
            ? litter3
            : BigInt(user.litter_calcul) >=
                BigInt(user.litter_limit) / BigInt(2)
              ? litter2
              : litter1
        }
        imageExec={litter1}
        imageLow={litter1}
        gasCost={c_actions[action_id][action_type].gas_cost}
        eneregyCost={c_actions[action_id][action_type].energy_cost}
        userGas={user.egld_amount}
        userCacat={user.cacat_amount}
        tokenCost={0}
        requiredClicks={10}
        onAction={postUserEmptyLitter}
        matchingAction={matchingAction}
        disabled={
          BigInt(user.litter_calcul) < BigInt(user.litter_limit) / BigInt(2) ||
          BigInt(user.egld_amount) <=
            BigInt(c_actions[action_id][action_type].energy_cost * 10 ** 13)
        }
      />
    </div>
  );
};

export default GetUserEmptyLitterDbButton;
