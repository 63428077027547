import React, { useEffect } from 'react';
import { useContext, useState } from 'react';
import { UserContext } from 'components/hooks/useUser';
import axios from 'axios';
import { defaultToken, network } from 'config';
import { formatAmount } from '@multiversx/sdk-dapp/utils/operations';
import BigNumber from 'bignumber.js';
import { Address } from '@multiversx/sdk-core';
import gas_logo from 'img/gas_logo.png';
import {
  useGetAccount,
  useGetIsLoggedIn,
  useGetSignedTransactions
} from 'hooks';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import {
  getTransactionStatus,
  refreshAccount
} from '@multiversx/sdk-dapp/utils';
import bigToHex from './helpers/bigToHex';
import { callback } from 'telegraf/typings/button';
import {
  useTrackTransactionStatus,
  useSignTransactions,
  useGetSuccessfulTransactions
} from '@multiversx/sdk-dapp/hooks';

const GetDepositEgld = ({ x_portal_balance, sendamount }: any) => {
  const [sendingAmount, setSendingAmount] = useState(new BigNumber(sendamount));
  //   const [trackId, setTrackId] = useState('');

  useEffect(() => {
    setSendingAmount(new BigNumber(sendamount));
  }, [sendamount]);

  // const { address } = useGetAccount();

  const context = useContext(UserContext);
  if (!context) {
    throw new Error('UserContextProvider');
  }
  const { user, setUser, refresh, setRefresh } = context;

  const /*transactionSessionId*/ [, setTransactionSessionId] = useState<
      string | null
    >(null);

  const sendDepositTransaction = async () => {
    // console.log('Sending amount:', sendingAmount);
    const depositTransaction = {
      value: sendingAmount,
      data: '',
      gasLimit: '500000',
      receiver: user.address
    };
    // console.log('Deposit transaction:', depositTransaction);
    await refreshAccount();

    const { sessionId /*, error*/ } = await sendTransactions({
      transactions: depositTransaction,
      transactionsDisplayInfo: {
        processingMessage: 'Processing deposit transaction',
        errorMessage: 'An error has occured depositing',
        successMessage: 'Deposit transaction successful'
      },
      redirectAfterSign: false
    });
    // console.log('Session ID1:', sessionId);
    // const transactionStatus = getTransactionStatus(sessionId);
    // console.log('Transaction status:', transactionStatus);

    if (sessionId != null) {
      setTransactionSessionId(sessionId);
    }
    return sessionId;
  };

  //   const {
  //     callbackRoute,
  //     transactions,
  //     error,
  //     sessionId,
  //     onAbort,
  //     hasTransactions,
  //     canceledTransactionsMessage
  //   } = useSignTransactions();
  //   console.log('sessionId', sessionId);
  //   //   console.log('Callback route:', callbackRoute);
  //   console.log(
  //     'Transactions:',
  //     transactions && transactions.length > 0
  //       ? transactions[0].getHash().toString()
  //       : 'No transactions'
  //   );
  const signedTransactions = useGetSuccessfulTransactions();
  //   console.log('Signed transactions:', signedTransactions);

  useEffect(() => {
    if (signedTransactions.hasSuccessfulTransactions) {
      //   console.log('we have transactions');
      setRefresh((r) => ({
        ...r,
        refreshing: true,
        user_egld: true
      }));
    }
  }, [signedTransactions.hasSuccessfulTransactions]);

  //   const signed = useGetSignedTransactions();
  //   console.log('Signed transactions:', signed);
  //Signed to explore and get has
  //   console.log('Session ID:', sessionId);
  //   console.log('On abort:', onAbort);
  //   console.log('Has transactions:', hasTransactions);
  //   console.log('Canceled transactions message:', canceledTransactionsMessage);

  user;
  return (
    <div>
      <div>
        <button
          onClick={sendDepositTransaction}
          type='submit'
          className='transfer-btn'
          disabled={
            sendingAmount.isZero() ||
            sendingAmount.isGreaterThan(x_portal_balance)
          }
        >
          {sendingAmount.isGreaterThan(x_portal_balance) ||
          sendingAmount.isZero() ? (
            <>Low xportal balance</>
          ) : (
            <>Confirm transfer</>
          )}
        </button>
      </div>
      {refresh.txHash && (
        <>
          {refresh.txHash.slice(0, 6)}...{refresh.txHash.slice(-6)} ⌛️
        </>
      )}
    </div>
  );
};

export default GetDepositEgld;
