import GetUserFeedUpgradeButton from 'components/helpers/postUserFeedUpgrade';
import GetUserLitterUpgradeButton from 'components/helpers/postUserLitterUpgrade';
import GetUserPetUpgradeButton from 'components/helpers/postUserPetUpgrade';
import GetUserPlayUpgradeButton from 'components/helpers/postUserPlayUpgrade';
import LobbyMenu from 'components/LobbyMenu';
import React from 'react';

const UpgradePage = () => {
  return (
    <div>
      <div className='grid-container'>
        <GetUserPetUpgradeButton />
        <GetUserPlayUpgradeButton />
        <GetUserFeedUpgradeButton />
        <GetUserLitterUpgradeButton />
      </div>
      <LobbyMenu back={'pet'} />
    </div>
  );
};

export default UpgradePage;
