import React, { useContext, useEffect, useState } from 'react';
import ShortenedAddress from 'components/ShortenedAddress';
import { UserContext } from 'components/hooks/useUser';
import { Link } from 'react-router-dom';
import GetWithdraw from 'components/helpers/postUserWithdraw';
import { useGetAccount, useGetIsLoggedIn } from 'hooks';
import './Transfer.css';
import { formatAmount } from '@multiversx/sdk-dapp/utils/operations';
import gas_logo from 'img/gas_logo.png';
import BigNumber from 'bignumber.js';
import GetDeposit from 'components/helpers/postUserDeposit';
import { defaultToken } from 'config';
import { useGetUserESDT } from '../Earn/components/helpers/useGetUserESDT';
import GetDepositEgld from 'components/helpers/postUserDepositEgld';
import LobbyMenu from 'components/LobbyMenu';

const WithdrawPage = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('UserContextProvider');
  }
  const { user } = context;
  const isLoggedIn = useGetIsLoggedIn();
  const { address } = useGetAccount();

  const [isTelegramToXportal, setIsTelegramToXportal] = useState(true);
  const [telegramAmount, setTelegramAmount] = useState(new BigNumber(0));
  const [xportalAmount, setXportalAmount] = useState(new BigNumber(0));
  const [sendingAmount, setSendingAmount] = useState(new BigNumber(0));
  const [inputAmount, setInputAmount] = useState(0);

  const userEsdtBalance: any[] = useGetUserESDT();

  // Rechercher le token spécifique et utiliser un fallback si non trouvé
  const cacat_balance =
    userEsdtBalance
      .find((item: any) => item.identifier === defaultToken)
      ?.balance.toString() ?? '0';

  useEffect(() => {
    setTelegramAmount(new BigNumber(user.cacat_amount));
    setXportalAmount(new BigNumber(cacat_balance));
  }, [user.cacat_amount, cacat_balance]);

  // Utility to convert user input (without decimals) into a BigInt with 18 decimals
  // const numerToBigN = (amount: any) => {
  //   const cleanAmount = parseFloat(amount.replace(',', '.')); // Ensure decimal point is consistent
  //   return BigInt(Math.floor(cleanAmount * 10 ** 18)); // Convert to BigInt with 18 decimals

  // };

  const convertToBigNumber = (amount: number) => {
    const cleanAmount = parseFloat(amount.toString().replace(',', '.'));
    return new BigNumber(Math.floor(cleanAmount * 10 ** 18));
  };

  // Utility to display BigInt with 18 decimals as a readable number
  const fromBigIntWithDecimals = (amountBigInt: any) => {
    return parseFloat((BigInt(amountBigInt) / BigInt(10 ** 18)).toString()); // Convert to human-readable number
  };

  const swapValues = () => {
    setSendingAmount(new BigNumber(0));
    setInputAmount(0);
    setIsTelegramToXportal(!isTelegramToXportal);
  };

  const handleInputChange = (event: any) => {
    setInputAmount(event.target.value);
    console.log(
      event.target.value,
      convertToBigNumber(event.target.value).toFixed()
    );

    const value = event.target.value.replace(',', '.');
    if (isNaN(value) || value === '' || parseFloat(value) < 0) {
      return;
    }
    setSendingAmount(convertToBigNumber(event.target.value));
  };

  const handleMaxClick = () => {
    if (isTelegramToXportal) {
      setInputAmount(fromBigIntWithDecimals(telegramAmount));
      setSendingAmount(telegramAmount);
    } else {
      setInputAmount(fromBigIntWithDecimals(xportalAmount));
      setSendingAmount(xportalAmount);
    }
  };

  return (
    <div>
      <div>
        Cacat wallet: <ShortenedAddress address={user?.address} />
        xPortal wallet: <ShortenedAddress address={address} />
      </div>

      {!isLoggedIn ? (
        <div>
          <Link to='/unlock' className='menu-button'>
            Unlock
          </Link>
        </div>
      ) : (
        <div className='transfer-container'>
          <h1>Transfer Funds Between Your Wallets</h1>

          <p className='transfer-info'>
            {isTelegramToXportal
              ? 'Transferring funds from Cacat Wallet to xPortal Wallet'
              : 'Transferring funds from xPortal Wallet to Cacat Wallet'}
          </p>

          <div className='wallets'>
            {/* Wallet 1 (modifiable) */}
            <div className='wallet'>
              <label htmlFor='amount-1'>
                {isTelegramToXportal
                  ? 'Cacat Wallet (Sending 💸)'
                  : 'xPortal Wallet (Sending 💸)'}
              </label>
              <div className='input-max-container'>
                <input
                  type='number'
                  id='amount-1'
                  className={'modifiable input-field'}
                  value={inputAmount}
                  onChange={handleInputChange}
                  placeholder='Enter amount'
                  min={100}
                />
                <button onClick={handleMaxClick} className='max-btn'>
                  MAX
                </button>
              </div>
            </div>

            {/* Swap Button avec flèche */}
            <button className='swap-btn' onClick={swapValues}>
              <span className='arrow'>⇅</span> Invert
            </button>

            {/* Wallet 2 (readonly) */}
            <div className='wallet'>
              <label htmlFor='amount-2'>
                {!isTelegramToXportal
                  ? 'Cacat Wallet (Receiving 💰)'
                  : 'xPortal Wallet (Receiving 💰)'}
              </label>
              <input
                type='text'
                id='amount-2'
                className={'readonly input-field'}
                value={
                  !isTelegramToXportal
                    ? formatAmount({
                        input: telegramAmount.toFixed(),
                        digits: 2,
                        showLastNonZeroDecimal: false
                      })
                    : formatAmount({
                        input: xportalAmount.toFixed(),
                        digits: 2,
                        showLastNonZeroDecimal: false
                      })
                }
                readOnly={true}
              />
            </div>
          </div>

          {/* Transfer Button */}
          <div className='transfer-btn-container'>
            {isTelegramToXportal ? (
              <>
                <GetWithdraw sendamount={sendingAmount} />
              </>
            ) : (
              <>
                <GetDeposit sendamount={sendingAmount} />

                {/* <button
                  className='transfer-btn'
                  onClick={() => alert(sendingAmount.toFixed())}
                >
                  {isTelegramToXportal && (
                    <>
                      -
                      {formatAmount({
                        input: '13000000000000000000',
                        decimals: 18,
                        digits: 2,
                        showLastNonZeroDecimal: false,
                        addCommas: true
                      })}
                      <img
                        className='inline-incon'
                        src={gas_logo}
                        alt='gas-logo'
                      />
                    </>
                  )}{' '}
                  Confirm Transfer
                </button> */}
              </>
            )}
          </div>
        </div>
      )}
      <LobbyMenu back={'wallet'} />
    </div>
  );
};

export default WithdrawPage;
